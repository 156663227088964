<template>
    <div class="create-new-client">
        <div v-if="sizeType=='full_screen'" class="steps">
            <el-steps :active="active" process-status="finish" finish-status="success" align-center class="steps-bar">
                <el-step title="פרטי העסק"></el-step>
                <el-step title="פרטי בעל העסק"></el-step>
                <el-step title="פרטי עסק נוספים"></el-step>
                <el-step title="פרטי תשלום"></el-step>
                <el-step title="פירוט ביקורים"></el-step>
                <el-step title="סיום"></el-step>
            </el-steps>
        </div>
        <div v-show="active==0" class="content">
            <div class="field">
                <p>שם הלקוח/בית העסק</p>
                <el-input v-model="values.client_name" placeholder="שם מלא" />
            </div>
            <div class="field">
                <p>ח.פ או ע.מ</p>
                <el-input v-model="values.business_id" placeholder="ח.פ או ע.מ" />
            </div>
            <div class="field">
                <p>כתובת</p>
                <el-input v-model="values.address" placeholder="רחוב, מספר" />
            </div>
            <div class="field">
                <p>עיר</p>
                <el-input v-model="values.city" placeholder="עיר" />
            </div>
            <div class="field">
                <p>הערות לכתובת</p>
                <el-input v-model="values.address_comments" placeholder="הערות" />
            </div>
            <div class="field">
                <p>מספר טלפון בבית העסק</p>
                <el-input type="tel"  v-model="values.business_phone" placeholder="מספר טלפון" />
            </div>
            <div class="field">
                <p>מספר טלפון נייד</p>
                <el-input type="tel" v-model="values.mobile_phone" placeholder="מספר נייד" />
            </div>
            <div class="field">
                <p>מספר פקס</p>
                <el-input type="tel" v-model="values.fax_number" placeholder="מספר פקס" />
            </div>
            <div class="field">
                <p>שם איש הקשר בעסק</p>
                <el-input v-model="values.contact_name" placeholder="שם איש הקשר בעסק" />
            </div>
            <div class="continue">
                <el-button type="success" @click="handle_submit_step_1">המשך</el-button>
            </div>
        </div>
        <div v-show="active==1" class="content">
            <div class="field">
                <p>שם בעל העסק (פרטי + משפחה)</p>
                <el-input v-model="values.owner_full_name" placeholder="שם מלא" />
            </div>
            <div class="field">
                <p>מס תז של בעל העסק</p>
                <el-input v-model="values.owner_id" placeholder="מס זהות של בעל העסק" />
            </div>
            <div class="field">
                <p>תאריך לידה של בעל העסק</p>
                <input type="date" v-model="values.owner_birth_date">
            </div>
            <div class="field">
                <p>כתובת מגורים של בעל העסק</p>
                <el-input v-model="values.owner_address" placeholder="כתובת מלאה של המגורים של בעל העסק" />
            </div>
            <div class="images">
                <div v-loading="uploading_pending=='תז'" class="image">
                    <p v-if="!values.owner_id_photo.url">צילום ת"ז</p>
                    <el-button v-if="!values.owner_id_photo.url" @click="handleUpload('תז')" type="primary" icon="el-icon-camera" circle></el-button>
                    <img v-if="values.owner_id_photo.url" :src="values.owner_id_photo.url">
                    <div v-if="values.owner_id_photo.url" class="delete-btn">
                        <i @click="handle_delete_img('תז')" class="material-icons">
                        delete_forever
                        </i>
                    </div>
                </div>
                <div v-loading="uploading_pending=='קופה'" class="image">
                    <p v-if="!values.owner_chash_photo.url">צילום סרט הקופה</p>
                    <el-button v-if="!values.owner_chash_photo.url" @click="handleUpload('קופה')" type="primary" icon="el-icon-camera" circle></el-button>
                    <img v-if="values.owner_chash_photo.url" :src="values.owner_chash_photo.url">
                    <div v-if="values.owner_chash_photo.url" class="delete-btn">
                        <i @click="handle_delete_img('קופה')" class="material-icons">
                        delete_forever
                        </i>
                    </div>
                </div>
                <div v-loading="uploading_pending=='ערבות'" class="image">
                    <p v-if="!values.owner_guaranty_photo.url">צילום ערבות</p>
                    <el-button v-if="!values.owner_guaranty_photo.url"  @click="handleUpload('ערבות')" type="primary" icon="el-icon-camera" circle></el-button>
                    <img v-if="values.owner_guaranty_photo.url" :src="values.owner_guaranty_photo.url">
                    <div v-if="values.owner_guaranty_photo.url" class="delete-btn">
                        <i @click="handle_delete_img('ערבות')" class="material-icons">
                        delete_forever
                        </i>
                    </div>
                </div>
                <div v-loading="uploading_pending=='עסק'" class="image">
                    <p v-if="!values.owner_business_front_photo.url">צילום חזית העסק</p>
                    <el-button v-if="!values.owner_business_front_photo.url"  @click="handleUpload('עסק')" type="primary" icon="el-icon-camera" circle></el-button>
                    <img v-if="values.owner_business_front_photo.url" :src="values.owner_business_front_photo.url">
                    <div v-if="values.owner_business_front_photo.url" class="delete-btn">
                        <i @click="handle_delete_img('עסק')" class="material-icons">
                        delete_forever
                        </i>
                    </div>
                </div>
            </div> 
            <div class="continue-and-before">
                <el-button type="danger" @click="--active">הקודם</el-button>
                <el-button type="success" @click="handle_submit_step_2">המשך</el-button>
            </div>         
        </div>
        <div v-show="active==2" class="content">
            <div class="field">
                <p>בחר את סוג העסק</p>
                <el-select style="width:100%;" v-model="values.business_type" placeholder="בחר את סוג העסק">
                    <el-option
                        v-for="type in business_type_options"
                        :key="type"
                        :value="type"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="field">
                <p>בחר מחירון לקוח</p>
                <el-select style="width:100%;" v-model="values.price_list" placeholder="בחר מחירון לקוח">
                    <el-option
                        v-for="price in price_list_options"
                        :key="price"
                        :value="price"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="field">
                <p>וותק העסק בשנים</p>
                <el-input-number class="input-number" v-model="values.business_seniority" :min="0" :max="50" />
            </div>
            <div class="field">
                <p>האם חזרו ללקוח צ'קים בעבר?</p>
                <el-select style="width:100%;" v-model="values.back_check" placeholder="האם חזרו ללקוחות צ'קים בעבר?">
                    <el-option
                        v-for="check in back_check_options"
                        :key="check"
                        :value="check"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="field">
                <p>הזן את מספר הקופות של הלקוח</p>
                <el-input-number class="input-number" v-model="values.kupot_qnt" :min="0" :max="1000" />
            </div>
            <div class="field">
                <p>בחר את רמת הכשרות של העסק</p>
                <el-select style="width:100%;" v-model="values.kosher_type" placeholder="רמת הכשרות של העסק">
                    <el-option
                        v-for="type in kosher_type_options"
                        :key="type"
                        :value="type"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="field">
                <p>בחר את המגדר העסקי של העסק</p>
                <el-select style="width:100%;" v-model="values.business_migdar" placeholder="בחר את המגדר העסקי של העסק">
                    <el-option
                        v-for="migdar in business_migdar_options"
                        :key="migdar"
                        :value="migdar"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="continue-and-before">
                    <el-button type="danger" @click="--active">הקודם</el-button>
                    <el-button @click="handle_submit_step_3" type="success">המשך</el-button>
            </div>
        </div>
        <div v-show="active==3" class="content">
            <div class="field">
                <p>בחר את תנאי התשלום</p>
                <el-select style="width:100%;" v-model="values.payment_type" placeholder="בחר את סוג העסק">
                    <el-option
                        v-for="type in payment_type_options"
                        :key="type"
                        :value="type"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.payment_type!='מזומן'" class="field">
                <p>בחר את שם הבנק</p>
                <el-select style="width:100%;" v-model="values.bank_name" placeholder="בחר את שם הבנק">
                    <el-option
                        v-for="type in bank_name_options"
                        :key="type"
                        :value="type"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.payment_type!='מזומן'" class="field">
                <p>מספר סניף</p>
                <el-input type="number" v-model="values.bank_branche_number" placeholder="מספר סניף בנק" />
            </div>
            <div v-if="values.payment_type!='מזומן'" class="field">
                <p>מספר חשבון</p>
                <el-input type="number" v-model="values.bank_account_number" placeholder="מספר חשבון בנק" />
            </div>
            <div v-if="values.payment_type!='מזומן'" class="field">
                <p>שם חשבון</p>
                <el-input v-model="values.bank_account_name" placeholder="שם החשבון" />
            </div>
            <div class="field">
                <p>ימי אשראי</p>
                <el-input v-model="values.credit_days" placeholder="נא לרשום בצורה הבאה: שוטף 60 או מזומן 30 וכדומה" />
            </div>
            <div class="field">
                <p>למי תהייה מסירת הצ'ק?</p>
                    <el-select style="width:100%;" v-model="values.check_delivery" placeholder="נא לבחור למי ימסר הצ'ק">
                    <el-option
                        v-for="type in check_delivery_options"
                        :key="type"
                        :value="type"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="continue-and-before">
                <el-button type="danger" @click="--active">הקודם</el-button>
                <el-button @click="handle_submit_step_4" type="success">המשך</el-button>
            </div>
        </div>
        <div v-show="active==4" class="content">
            <div class="field">
                <p>יום בשבוע לביקור סוכן</p>
                <el-select style="width:100%;" v-model="values.visit_days" placeholder="יום בשבוע לביקור סוכן">
                    <el-option
                        v-for="day in visit_days_options"
                        :key="day"
                        :value="day"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="field">
                <p>תדירות ביקורים</p>
                <el-select style="width:100%;" v-model="values.visit_frequency"  allow-create placeholder="יום בשבוע לביקור סוכן">
                    <el-option
                        v-for="day in visit_frequency_options"
                        :key="day"
                        :value="day"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.visit_frequency=='כמו לקוח'" class="field">
                <p>תדירות ביקורים כמו לקוח מס:</p>
               <el-input type="tel" v-model="values.visit_like_customer" placeholder="רשום את מספר הלקוח" />
            </div>

            <div v-if="values.visit_frequency=='פעמיים בשבוע'" class="field">
                <p>סוג הביקור</p>
                <el-select style="width:100%;" v-model="values.visit_frequency_twice_a_week" placeholder="בחר את סוג הביקור">
                    <el-option
                        v-for="option in visit_frequency_twice_a_week_options"
                        :key="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.visit_frequency=='פעם בחודש'" class="field">
                <p>סוג הביקור</p>
                <el-select style="width:100%;" v-model="values.visit_frequency_once_a_month" placeholder="בחר את סוג הביקור">
                    <el-option
                        v-for="option in visit_frequency_once_a_month_options"
                        :key="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="field">
                <p>האם שעות קבלת הסחורה מוגבלות?</p>
                <el-select style="width:100%;" v-model="values.is_visit_limit" placeholder="שעות קבלת הסחורה מוגבלות?">
                    <el-option
                        v-for="answer in is_visit_limit_options"
                        :key="answer"
                        :value="answer"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.is_visit_limit=='כן'" class="field">
                <div class="time">
                    <p>משעה</p>
                    <input v-model="values.visit_limit_hour_start" type="time">
                </div>
                <div class="time">
                    <p>עד שעה</p>
                    <input v-model="values.visit_limit_hour_end" type="time">
                </div>
                
                <!-- <el-input-number class="input-number" v-model="values.visit_limit_hours" :min="0" :max="50" /> -->
            </div>
            <div class="continue-and-before">
                <el-button type="danger" @click="--active">הקודם</el-button>
                <el-button @click="handle_submit_step_5"  type="success">המשך</el-button>
            </div>
        </div>
        <div v-show="active==5" class="content">
            <div v-if="sizeType=='full_screen'" class="field">
                <p>אני הח"מ בעל העסק הנ"ל מאשר את כלל הפרטים האמורים לעיל</p>
                <SignaturePad idName="paint-1" sketchName="sketchName-1" :width="500" />
                <el-button @click="handle_submit_step_6" style="width:500px;" type="success">מאשר וסיים</el-button>
            </div>
            <div v-if="sizeType=='mobile'" class="field">
                <p>אני הח"מ בעל העסק הנ"ל מאשר את כלל הפרטים האמורים לעיל</p>
                <SignaturePad idName="paint-1" sketchName="sketchName-1" :width="300" />
                <el-button @click="handle_submit_step_6" style="width:300px;" type="success">מאשר וסיים</el-button>
            </div>
        </div>
        <input v-show="false" id="upload-image" type="file" @change="handleChange"/>
          <!-- just for mail -->
        <div class="form-email" id="form-email" v-show="false">
            <table
                style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
            >
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        נוצר בתאריך
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ new Date().toLocaleDateString('he') }}
                    </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מספר הסוכן
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ agent_number }}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    שם הסוכן
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ agent_name }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    שם הלקוח / בית העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.client_name}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    ח.פ או ע.מ
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.business_id }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    כתובת העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.address}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    עיר
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.city }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    הערות לכתובת
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.address_comments}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מס' טלפון בבית העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.business_phone }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מס' טלפון נייד
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.mobile_phone}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מס' פקס
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.fax_number }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    שם איש הקשר בעסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.contact_name}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    שם בעל העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.owner_full_name }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מס' תז של בעל העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.owner_id}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    תאריך לידה של בעל העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ new Date(values.owner_birth_date).toLocaleDateString('he') }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    כתובת מגורים של בעל העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.owner_address}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    סוג העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.business_type }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מחירון לקוח
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.price_list}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    וותק העסק בשנים
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.business_seniority }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    האם חזרו ללקוח צ'קים בעבר?
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.back_check}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מספר הקופות של הלקוח
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.kupot_qnt }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    רמת הכשרות של העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.kosher_type}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    המגדר העסקי של העסק
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.business_migdar }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    תנאי התשלום
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.payment_type}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    שם הבנק 
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.bank_name }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מספר סניף
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.bank_branche_number}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    מספר חשבון 
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.bank_account_number }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    שם חשבון
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.bank_account_name}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    ימי אשראי
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.credit_days }}
                </td>
                </tr>
                <tr>
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    למי תהייה מסירת הצ'ק?
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.check_delivery}}
                </td>
                </tr>
                <tr style="background-color: #dddddd;">
                <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    יום בשבוע לביקור סוכן
                </th>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                    {{ values.visit_days }}
                </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        תדירות ביקורים
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.visit_frequency}}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;" v-if="values.visit_frequency=='פעמיים בשבוע'">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        סוג הביקור
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.visit_frequency_twice_a_week}}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;" v-if="values.visit_frequency=='פעם בחודש'">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        סוג הביקור
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.visit_frequency_once_a_month}}
                    </td>
                </tr>
                <tr v-if="values.visit_frequency=='כמו לקוח'">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        סוג הביקור
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        כמו לקוח מספר:{{values.visit_like_customer}}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        האם שעות קבלת הסחורה מוגבלות?
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.is_visit_limit }}
                    </td>
                </tr>
                <tr v-if="values.is_visit_limit=='כן'">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        שעות קבלת הסחורה המוגבלות
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        משעה:{{values.visit_limit_hour_start}} עד שעה:{{values.visit_limit_hour_end}}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        צילום ת"ז
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="values.owner_id_photo.url" style="width:200px; height:200px;" />
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        צילום סרט הקופה
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="values.owner_chash_photo.url" style="width:200px; height:200px;" />
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        צילום ערבות
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="values.owner_guaranty_photo.url" style="width:200px; height:200px;" />
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        צילום חזית העסק
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="values.owner_business_front_photo.url" style="width:200px; height:200px;" />
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        חתימה
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="values.signature.url" style="width:200px; height:200px;" />
                    </td>
                </tr>       
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../Methods/Msgs'
import use_agents_storage from '../../../Methods/use_agents_storage'
import SignaturePad from '../../../components/signature/SignaturePad.vue'
import {projectFirestore,projectFunctions} from '../../../firebase/config'
import { computed, onMounted } from '@vue/runtime-core'
import {isDrawnOn} from '../../../Methods/Signature_funcs'
import store from '../../../store'


export default {
    props:['agent_name'],
    emits:['close'],
    components:{SignaturePad},
    setup(props,{emit}){
        const sizeType = ref('full_screen')
        const {url, filePath, uploadImage, deleteImage} = use_agents_storage()
        const uploading_pending = ref(false)
        const type_upload = ref('')
        const file = ref(null)
        const agent_number = ref(computed(()=>{
            if(store.getters.user){
                return store.getters.user.agent
            }
        }))
        const business_type_options = ref([
            'מכולת/מעדנייה',
            'מינימרקט (קופה אחת)',
            'סופר מרקט (2 קופות ומעלה)',
            'פיצוצייה / דרגסטור',
            'אחר'
        ])
        const price_list_options = ref([
            'בסיס',
            'פלטינום',
            'זהב',
            'סיטונאי',
            'מחירון אישי'
        ])
        const back_check_options = ref([
            'כן',
            'לא'
        ])
        const kosher_type_options = ref([
            'לא כשר',
            'כשרות רגילה',
            'כשרות גבוהה'
        ])
        const business_migdar_options = ref([
            'רוסי',
            'ישראלי',
            'ערבי'
        ])
        const payment_type_options= ref([
            'מזומן',
            'המחאה - שיק',
            'העברה בנקאית'
        ])
        const bank_name_options = ref([
            'לאומי',
            'הפועלים',
            'דיסקונט',
            'מרכנתיל דיסקונט',
            'הבינלאומי',
            'מסד',
            'יהב',
            'הדואר',
            'ירושלים',
            'אחר'
        ])
        const check_delivery_options = ref([
            'סוכן',
            'נהג'
        ])
        const visit_days_options = ref([
           'א',
           'ב',
           'ג',
           'ד',
           'ה',
           'ו'
       ])
        const visit_frequency_options = ref([
            'פעם בשבוע',
            'פעמיים בשבוע',
            'פעם בחודש',
            'כמו לקוח'
        ])
        const is_visit_limit_options=ref([
            'כן',
            'לא'
        ])
        const visit_frequency_twice_a_week_options = ref([
            '',
            '1 פלוס 3',
            '2 פלוס 4'
        ])
        const visit_frequency_once_a_month_options = ref([
            '',
            'שבוע-1',
            'שבוע-2',
            'שבוע-3',
            'שבוע-4'
        ])
        const error_msg = ref('')
        const active = ref(0)
        const values = ref({
            createdAt:new Date(),
            client_name:'',
            business_id:'',
            address:'',
            city:'',
            address_comments:'',
            business_phone:'',
            mobile_phone:'',
            fax_number:'',
            contact_name:'',
            owner_full_name:'',
            owner_id:'',
            owner_birth_date:'',
            owner_address:'',
            owner_id_photo:{
                url:'',
                file_path:''
            },
            owner_chash_photo:{
                url:'',
                file_path:''
            },
            owner_guaranty_photo:{
                url:'',
                file_path:''
            },
            owner_business_front_photo:{
                url:'',
                file_path:''
            },
            business_type:'',
            price_list:'',
            business_seniority:'',
            back_check:'',
            kupot_qnt:'',
            kosher_type:'',
            business_migdar:'',
            payment_type:'',
            bank_name:'',
            bank_branche_number:'',
            bank_account_number:'',
            bank_account_name:'',
            credit_days:'',
            check_delivery:'',
            visit_days:'',
            visit_frequency:'',
            visit_frequency_twice_a_week:'',
            visit_frequency_once_a_month:'',
            visit_like_customer:'',
            is_visit_limit:'',
            visit_limit_hour_start:'',
            visit_limit_hour_end:'',
            signature:{
                url:'',
                file_path:''
            },
            agent_name:props.agent_name
        })

        const handleUpload = (name)=>{
             type_upload.value = name
             document.getElementById("upload-image").click();
        }

        const handle_delete_img=async(name)=>{
            if(name=='תז'){
                await deleteImage(values.value.owner_id_photo.file_path)
                values.value.owner_id_photo.url=''
                values.value.owner_id_photo.file_path=''

            }
            if(name=='קופה'){
                await deleteImage(values.value.owner_chash_photo.file_path)
                values.value.owner_chash_photo.url=''
                values.value.owner_chash_photo.file_path=''
            }
            if(name=='ערבות'){
                await deleteImage(values.value.owner_guaranty_photo.file_path)
                values.value.owner_guaranty_photo.url=''
                values.value.owner_guaranty_photo.file_path=''
            }
            if(name=='עסק'){
                await deleteImage(values.value.owner_business_front_photo.file_path)
                values.value.owner_business_front_photo.url=''
                values.value.owner_business_front_photo.file_path=''
                console.log(values.value);
            }
        }

        const handleChange=async(e)=>{
            const types = ["image/png", "image/jpeg", "image/jpg"];
             const selected = e.target.files[0];
             if (selected && types.includes(selected.type)) {
                 file.value = selected;
                 if(type_upload.value=='תז'){
                     uploading_pending.value='תז'
                     await uploadImage(file.value,props.agent_name,values.value.client_name);
                     values.value.owner_id_photo.url = url.value
                     values.value.owner_id_photo.file_path = filePath.value
                     uploading_pending.value=false
                 }
                 if(type_upload.value=='קופה'){
                     uploading_pending.value='קופה'
                     await uploadImage(file.value,props.agent_name,values.value.client_name);
                     values.value.owner_chash_photo.url = url.value
                     values.value.owner_chash_photo.file_path = filePath.value
                     uploading_pending.value=false
                 }
                 if(type_upload.value=='ערבות'){
                     uploading_pending.value='ערבות'
                     await uploadImage(file.value,props.agent_name,values.value.client_name);
                     values.value.owner_guaranty_photo.url = url.value
                     values.value.owner_guaranty_photo.file_path= filePath.value
                     uploading_pending.value=false
                 }
                 if(type_upload.value=='עסק'){
                    uploading_pending.value='עסק'
                    await uploadImage(file.value,props.agent_name,values.value.client_name);
                    values.value.owner_business_front_photo.url = url.value
                    values.value.owner_business_front_photo.file_path = filePath.value
                    uploading_pending.value=false
                 }
             }else{
                file.value = null;
                slide_pop_error('סוג קובץ זה אינו נתמך')
             }
        }

        //step-1
        const validation_step_1 = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'client_name':
                        if(!value){
                            error_msg.value = 'עליך למלא שם לקוח/בית עסק'
                            return false
                        }
                        break;
                    case 'business_id':
                        if(!value){
                            error_msg.value = 'עליך למלא ח.פ או ע.מ'
                            return false
                        }
                        break;
                    case 'address':
                        if(!value){
                            error_msg.value = 'עליך למלא כתובת'
                            return false
                        }
                        break;
                    case 'city':
                        if(!value){
                            error_msg.value = 'עליך למלא עיר'
                            return false
                        }
                        break;
                    case 'address_comments':
                        if(!value){
                            error_msg.value = 'עליך למלא הערות לכתובת'
                            return false
                        }
                        break;
                    case 'business_phone':
                        if(!value || value.length!=9){
                            error_msg.value = 'עליך למלא טלפון בבית העסק בצורה תקינה'
                            return false
                        }
                        break;
                    case 'mobile_phone':
                        if(!value || value.length!=10){
                            error_msg.value = 'עליך למלא טלפון נייד בצורה תקינה'
                            return false
                        }
                        break;
                    case 'contact_name':
                        if(!value){
                            error_msg.value = 'עליך למלא שם איש קשר'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit_step_1=()=>{
            if(!validation_step_1()){
                slide_pop_error(error_msg.value)
            }else{
                active.value = active.value + 1
            }
        }

        //step-2
        const validation_step_2 = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'owner_full_name':
                        if(!value){
                            error_msg.value='עליך למלא שם מלא של בעל העסק'
                            return false
                        }
                        break;
                    case 'owner_id':
                        if(value.length<6){
                            error_msg.value='עליך למלא מס זהות תקינה של בעל העסק'
                            return false
                        }
                        break;
                    case 'owner_birth_date':
                        if(!value){
                            error_msg.value='עליך למלא תאריך לידה של בעל העסק'
                            return false
                        }
                        break;
                    case 'owner_address':
                        if(!value){
                            error_msg.value='עליך למלא כתובת מגורים של בעל העסק'
                            return false
                        }
                        break;
                    case 'owner_id_photo':
                        if(!value.url){
                            error_msg.value='עליך לצלם את התעודת זהות של בעל העסק'
                            return false
                        }
                        break;
                    case 'owner_chash_photo':
                        if(!value.url){
                            error_msg.value='עליך לצלם את סרט הקופה של בעל העסק'
                            return false
                        }
                        break;
                    case 'owner_guaranty_photo':
                        if(!value.url){
                            error_msg.value='עליך לצלם ערבות'
                            return false
                        }
                        break;
                    case 'owner_business_front_photo':
                        if(!value.url){
                            error_msg.value='עליך לצלם את חזית העסק'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit_step_2 = ()=>{
            if(!validation_step_2()){
                slide_pop_error(error_msg.value)
            }else{
                active.value = active.value + 1
            }
        }

        //step-3
        const validation_step_3 = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'business_type':
                        if(!value){
                            error_msg.value='עליך להזין את סוג העסק'
                            return false
                        }
                        break;
                    case 'price_list':
                        if(!value){
                            error_msg.value='עליך להזין את מחירון הלקוח'
                            return false
                        }
                        break;
                    case 'business_seniority':
                        if(!value){
                            error_msg.value='עליך להזין את וותק העסק בשנים'
                            return false
                        }
                        break;
                    case 'back_check':
                        if(!value){
                            error_msg.value='עליך להזין האם חזרו ללקוח צקים בעבר'
                            return false
                        }
                        break;
                    case 'kupot_qnt':
                        if(!value){
                            error_msg.value='עליך להזין את מספר הקופות של הלקוח'
                            return false
                        }
                        break;
                    case 'kosher_type':
                        if(!value){
                            error_msg.value='עליך להזין את רמת הכשרות של העסק'
                            return false
                        }
                        break;
                    case 'business_migdar':
                        if(!value){
                            error_msg.value='עליך להזין את המגדר העסקי של העסק'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit_step_3 = ()=>{
            if(!validation_step_3()){
                slide_pop_error(error_msg.value) 
            }else{
                active.value=active.value+1
            }
        }

        //step-4
        const validation_step_4 = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'payment_type':
                        if(!value && values.value.payment_type!='מזומן'){
                            error_msg.value = 'עליך לבחור תנאי תשלום'
                            return false
                        }
                        break;
                    case 'bank_name':
                        if(!value && values.value.payment_type!='מזומן'){
                            error_msg.value = 'עליך לבחור את שם הבנק'
                            return false
                        }
                        break;
                    case 'bank_branche_number':
                        if(!value && values.value.payment_type!='מזומן'){
                            error_msg.value = 'עליך להזין מספר סניף של הבנק'
                            return false
                        }
                        break;
                    case 'bank_account_number':
                        if(!value && values.value.payment_type!='מזומן'){
                            error_msg.value = 'עליך להזין מספר חשבון של הבנק'
                            return false
                        }
                        break;
                    case 'bank_account_name':
                        if(!value && values.value.payment_type!='מזומן'){
                            error_msg.value = 'עליך להזין את שם החשבון של הבנק'
                            return false
                        }
                        break;
                    case 'credit_days':
                        if(!value){
                            error_msg.value = 'עליך להזין ימי אשראי'
                            return false
                        }
                        break;
                    case 'check_delivery':
                        if(!value){
                            error_msg.value = 'עליך להזין למי תהייה מסירת הצק'
                            return false
                        }
                        break;
                }
            }
            return true
        } 
        const handle_submit_step_4 = ()=>{
            if(!validation_step_4()){
                slide_pop_error(error_msg.value)
            }else{
                active.value = active.value + 1
            }
        }
        
        //step-5
        const validation_step_5 = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'visit_days':
                        if(!value){
                            error_msg.value = 'עליך להזין יום בשבוע לביקור סוכן'
                            return false
                        }
                        break;
                    case 'visit_frequency':
                        if(!value){
                            error_msg.value = 'עליך להזין תדירות ביקורים'
                            return false
                        }
                        if(value=='פעמיים בשבוע'){
                            if(!values.value.visit_frequency_twice_a_week){
                                error_msg.value = 'בחר את סוג הביקור'
                                return false
                            }
                        }
                        if(value=='פעם בחודש'){
                            if(!values.value.visit_frequency_once_a_month){
                                error_msg.value = 'בחר את סוג הביקור'
                                return false
                            }
                        }
                        if(value=='כמו לקוח'){
                             if(!values.value.visit_like_customer){
                                error_msg.value = 'רשום מספר לקוח'
                                return false
                            }
                        }
                        break;
                    case 'is_visit_limit':
                        if(!value){
                            error_msg.value = 'עליך להזין האם שעות קבלת הסחורה מוגבלות'
                            return false
                        }
                        if(value=='כן'){
                            if(!values.value.visit_limit_hour_start || !values.value.visit_limit_hour_end){
                                error_msg.value = 'עליך להזין שעת התחלה ושעת סיום'
                                return false
                            }
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit_step_5 = ()=>{
            if(!validation_step_5()){
                slide_pop_error(error_msg.value)
            }else{
                active.value = active.value + 1
            }
        }

        //step-6 finish
        const validation_step_6 = ()=>{
            if(!signatureValidate('paint-1')){
                error_msg.value='עליך לחתום לפני אישור'
                return false
            }
            return true
        }
        const handle_submit_step_6 = async()=>{
            if(!validation_step_6()){
                slide_pop_error(error_msg.value)
            }else{
                values.value.signature.url = await getCanvasUrl('paint-1')
                values.value.signature.file_path = filePath.value
                await store_data_in_db()
                sendEmail()
                alert('success','תודה רבה','הטופס נשלח בהצלחה')
                .then(()=>{
                    emit('close')
                })


            }
        }
        const getCanvasUrl = (id) => {
            return new Promise(resolve => {
                const signPadElement = document.getElementById(id);
                signPadElement.toBlob(async function(blob) {
                blob.name = 'signature'
                await uploadImage(blob,props.agent_name,values.value.client_name);
                resolve(url.value);
                });
            });
        };
        const signatureValidate = paint => {
            const canvas = document.getElementById(paint);
            return isDrawnOn(canvas)
        };
        const store_data_in_db = async()=>{
            await projectFirestore.collection('Agent_portal').doc('Agent_portal')
            .collection('New_client').doc().set(values.value)
        }

        function sendEmail() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:['eldad@rosman.co.il','nir.rosman@gmail.com','yavrosman@gmail.com','mosescorcias@gmail.com'],
              from:"פורטל סוכנים",
              title:`נוצר לקוח חדש ברוסמן ע"י הסוכן:${props.agent_name}`
          })
        }
        window.addEventListener('resize', () => {
            if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
        });
        onMounted(()=>{
            if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
        })
        return{
            agent_number,
            sizeType,
            visit_days_options,
            handle_submit_step_4,
            check_delivery_options,
            bank_name_options,
            payment_type_options,
            handle_submit_step_3,
            business_migdar_options,
            kosher_type_options,
            back_check_options,
            price_list_options,
            business_type_options,
            visit_frequency_twice_a_week_options,
            visit_frequency_once_a_month_options,
            handle_submit_step_2,
            uploading_pending,
            handle_delete_img,
            handleUpload,
            values,
            active,
            error_msg,
            handle_submit_step_1,
            handleChange,
            visit_frequency_options,
            is_visit_limit_options,
            handle_submit_step_5,
            handle_submit_step_6
        }
    }
}

</script>

<style scoped>
    .create-new-client{
        width: 100%;
        max-width: 700px;
        height: 100%;
    }
    .steps{
        position: relative;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
    }
    .step{
        width: 80%;
        height: 100%;
    }
    .steps-bar{
        direction: ltr;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .content{
        width: 100%;
        height: 90%;
        overflow:hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: 18px;
    }
    .content .field{
        padding: 10px;
        margin-top: 10px;
        width: 80%;
        height: fit-content;
    }
    .content .field .time{
        width: 50%;
        display: inline-block;
        padding: 0 5px;
    }
    .continue{
        padding: 10px;
        margin-top: 10px;
        width: 80%;
        height: fit-content;
        display: flex;
        justify-content: flex-end;

    }
    .continue-and-before{
        width: 80%;
        height: fit-content;
        padding: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
    input[type="date"] {
        width: 100%;
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    input[type="time"] {
        width: 100%;
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="time"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="time"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .images{
        width: 80%;
        min-height: 400px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 5px;
    }
    .image{
        position: relative;
        width:100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .image img{
        max-width: 100%;
        max-height: 100%;
    }
    .delete-btn{
        position: absolute;
        width: 15%;
        height: 20%;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: crimson;
        color: #fff;
        z-index: 5;
        border-radius: 5px;
        font-size: 20px;
    }
    .delete-btn i{
        cursor: pointer;
    }
    .input-number{
        width: 100%;
    }
    
</style>