export function isDrawnOn(canvas) {
    var context = canvas.getContext("2d");
    var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;
    var result = null;
    var counter = 0;
    var last = data.length - 1;
    for (var i = 0; i < data.length; i++) {
      if (i === last && counter !== 0 && counter === last) {
        result = false;
        break;
      } else if (data[i] !== 0 && data[i] > 0) {
        result = true;
        break;
      } else {
        counter++;
        continue;
      }
    }
    return result;
}