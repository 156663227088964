<template>
  <div class="new-client">
      <div v-if="sizeType=='full_screen'" class="header">
          <div  class="agent-details">
                <h3>סוכן מייצג:{{current_agent_name}}</h3>
                <p>תאריך:{{new Date().toLocaleDateString('he')}}, {{new Date().toLocaleTimeString('he')}}</p>
          </div>
          <div class="options-btns">
              <div class="btn" @click="state='לקוח חדש'">
                  <el-button style="height:100%;" type="success">לקוח חדש ברוסמן</el-button>
              </div>
              <div class="btn">
                  <el-button style="height:100%;" type="success">עדכון פרטי לקוח</el-button>
              </div>
              <div class="btn">
                  <el-button style="height:100%;" type="success">סניף נוסף ללקוח קיים</el-button>
              </div>
          </div>
      </div>
      <div v-if="sizeType=='mobile'" class="header-mobile">
           
            <el-button class="btn-mobile" @click="state='לקוח חדש'" type="success">לקוח חדש ברוסמן</el-button>
            <el-button class="btn-mobile" type="success">עדכון פרטי לקוח</el-button>
            <el-button class="btn-mobile" type="success">סניף נוסף ללקוח קיים</el-button>
         
      </div>
      <div class="content">
          <CreateNewclient @close="state=null" :agent_name="current_agent_name" v-if="state=='לקוח חדש'"/>
      </div>
  </div>
</template>

<script>
import CreateNewclient from '../../components/Agent_portal/client/CreateNewclient.vue'
import { computed, onMounted, ref } from '@vue/runtime-core'
import store from '../../store'
export default {
    components:{CreateNewclient},
    setup(){
        const sizeType = ref('full_screen')
        const current_agent_name=ref(computed(()=>{
            if(store.getters.user){
                return store.getters.user.display_name
            }
        }))
        const state = ref(null)
        window.addEventListener('resize', () => {
            if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
        });
        onMounted(()=>{
            if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
        })
        return{state,current_agent_name,sizeType}
    }
}
</script>

<style scoped>
    .new-client{
        width: 100%;
        height: 100%;
        background-color: var(--secondary);
    }
    .header{
        padding: 10px;
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        color: #fff;
    }
    .header-mobile{
        width: 100%;
        height: 10%;
        color: #333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        overflow-x: auto;
    }
    .btn-mobile{
        flex-shrink: 0;
        margin: 0;
        margin-left: 2px;

    }
    .header .agent-details{
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .options-btns{
        width: 75%;
        height: 100%;
        display: flex;
    }
    .btn{
        width: calc(100% / 3);
        height: 100%;
        text-align: start;
    }
    .content{
        width: 100%;
        max-width: 100%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        overflow:hidden;
    }
</style>